import { computed, Injectable, signal, effect, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable, throwError, of, forkJoin } from 'rxjs';
import { tap, catchError, take, switchMap, mergeMap, map } from 'rxjs/operators';
import { datadogRum } from '@datadog/browser-rum';
import { CONFIG } from '../../environments/environment';
import { parseISO, isBefore, differenceInDays, addDays, add, format, isAfter } from 'date-fns';
import { HeapService } from './heap.service';
import { EventBusService } from '../_shared/event-bus.service';
import { TrialType } from '../_types/trialType';
import { NotificationService } from './notification.service';
import { User } from '../_types/user';
import { VendorPreference } from '../_types/vendor';
import { FeatureService } from './feature.service';

const authUrl = CONFIG.API_URL + 'auth';
const userUrl = CONFIG.API_URL + 'users';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};
export interface UserProfileData {
  cmmsUserId: number | null;
  cmmsCustomerId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  superUser: boolean | null;
}

export enum UserAccessStatus {
  Full,
  Trial,
  ExpiredTrial,
  SearchCountExceeded,
  NoAccess
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly userAccessStatus = new BehaviorSubject<UserAccessStatus | null>(null);
  private readonly _error = new BehaviorSubject<any>(null);
  private readonly _accountUpdated = new Subject<void>();
  private readonly _daysInTrialRemaining = new Subject<number>();
  private readonly _periodResetDate = new Subject<string>();

  readonly userAccessStatus$ = this.userAccessStatus.asObservable();
  readonly daysInTrialRemaining$ = this._daysInTrialRemaining.asObservable();
  readonly error$ = this._error.asObservable();
  readonly accountUpdated$ = this._accountUpdated.asObservable();
  readonly periodResetDate$ = this._periodResetDate.asObservable();

  readonly user = signal<User | null>(null);
  readonly isLoggedIn = computed(() => this.user() !== null);

  readonly profileData = signal<UserProfileData | null>(null);
  readonly isCmmsUser = signal<boolean>(false);
  readonly superUser = signal<boolean>(false);
  readonly superUserEmail = signal<string>('');
  readonly apiKey = signal<string>('');
  readonly allowCmmsLogin = signal<boolean>(true);
  readonly showCmmsLogin = computed(() => (!CONFIG.API_URL.includes('limble.com') ? true : false));

  constructor(
    private http: HttpClient,
    private readonly heapService: HeapService,
    private readonly eventService: EventBusService,
    private readonly notificationService: NotificationService
  ) {
    effect(() => {
      const user = this.user();
      if (user) {
        this.initMonitoring(user);
        this.heapService.identifyUser(user);
      }
    });
  }

  public saveUser(user: User): void {
    this.user.set(user);
    this.checkUserAccessStatus(user);
    this.updateCMMSUser(user);
    this.setUserProfile(user);
  }

  /**
   * Checks if user has full access or is on a free trial
   */
  public checkUserAccessStatus(user: User) {
    const { searchesRemaining, customer } = user;
    const { subscriptions } = customer;
    // a logged in user with no subscription will be from cmms.
    if (!subscriptions || !subscriptions.length) {
      this.userAccessStatus.next(UserAccessStatus.NoAccess);
      return;
    }

    const subscription = subscriptions[0];
    const { currentPeriodEnd, subscriptionTier } = subscription;
    const { renewable } = subscriptionTier;
    const now = new Date();
    const currentPeriodEndDate = parseISO(currentPeriodEnd);
    const periodResetDate = addDays(currentPeriodEndDate, 1);
    this._periodResetDate.next(format(periodResetDate, 'MMM d, yyyy'));

    // legacy users will have a null searchesRemaining value, this logic never applies to trials
    if (searchesRemaining !== null && searchesRemaining <= 0 && renewable) {
      this.userAccessStatus.next(UserAccessStatus.SearchCountExceeded);
      return;
    }

    // must follow subscription state because on conversion from trial to subscription, frontend subscription state is not immediately updated
    if (!renewable) {
      const expired = isAfter(now, currentPeriodEndDate);
      // expired trials will get automatically upgraded to a subscription in the backend so set full access state
      if (expired) {
        this.userAccessStatus.next(UserAccessStatus.Full);
        return;
      }
      const trialDaysRemaining = differenceInDays(currentPeriodEndDate, now);
      // check for partial days and round up if necessary
      if (currentPeriodEndDate > add(now, { days: trialDaysRemaining })) {
        this._daysInTrialRemaining.next(trialDaysRemaining + 1);
      } else {
        this._daysInTrialRemaining.next(trialDaysRemaining);
      }

      this.userAccessStatus.next(UserAccessStatus.Trial);
      return;
    }

    this.userAccessStatus.next(UserAccessStatus.Full);
  }

  public deleteUser(): void {
    this.user.set(null);
    this.removeCMMSUser();
  }

  public initMonitoring(user: User): void {
    const url = window.location.href;
    let env;

    if (url.includes('search.limble.com')) {
      env = 'prod';
    } else if (url.includes('partosphere.limblestaging.com')) {
      env = 'staging';
    } else {
      return;
    }

    datadogRum.init({
      applicationId: '039d5bd0-a961-440a-bb1f-58c5a5c007e5',
      clientToken: 'pub3356d988227e1831b3c70508fb22709b',
      site: 'datadoghq.com',
      service: 'limble-search',
      env: env ?? '',
      // TODO: version: this.limbleVersion ?? "",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [
        (traceURL: string) => traceURL.startsWith('https://search.limble.com'),
        (traceURL: string) => traceURL.startsWith('https://partosphere.limblestaging.com'),
        (traceURL: string) => traceURL.startsWith('https://api.search.limble.com'),
        (traceURL: string) => traceURL.startsWith('https://api.partosphere.limblestaging.com')
      ],
      beforeSend: (event: any) => {
        // discard a RUM error if status code is 401, that just means that got timed out of the app.
        //see these docs: https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#discard-a-frontend-error
        if (event?.resource?.status_code === 401 || event?.resource?.status_code === 403) {
          return false;
        }
        return true;
      }
    });

    datadogRum.setUser({
      id: `${user.id}`,
      customerID: user.customer.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email
    });

    datadogRum.startSessionReplayRecording();
  }

  login(email: string, password: string): Observable<{ user: User }> {
    const request = this.http
      .post<{ user: User }>(
        authUrl + '/login',
        {
          email,
          password
        },
        httpOptions
      )
      .pipe(
        tap({
          next: ({ user }) => {
            this.saveUser(user);
            this._error.next(null);
          },
          error: (response) => {
            this._error.next(response.error.message);
          }
        })
      );

    return request;
  }

  setUserProfile(user: User): void {
    if (user) {
      this.profileData.set({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        companyName: user.customer.name,
        superUser: user.superUser ?? false,
        cmmsUserId: user.cmmsUserId,
        cmmsCustomerId: user.customer.cmmsCustomerId
      });
      this.checkUserAccessStatus(user);
    }
  }

  updateAccount(
    firstName: string,
    lastName: string,
    companyName: string,
    email: string,
    currentPassword: string,
    password: string
  ): Observable<User> {
    const user = this.user();
    if (!user) {
      const errorMessage = 'Current user not logged in, cannot update account';
      this._error.next(errorMessage);
      this.notificationService.error(errorMessage);
      return throwError(() => new Error(errorMessage));
    }

    return this.http
      .post<{ user: User }>(
        `${userUrl}/${user.id}`,
        {
          firstName,
          lastName,
          companyName,
          email,
          password,
          currentPassword
        },
        httpOptions
      )
      .pipe(
        tap({
          next: (data: { user: User }) => {
            this.saveUser(data.user);
            this._error.next(null);
            this._accountUpdated.next();
            this.notificationService.success('Account updated successfully!');
          },
          error: (response) => {
            this._error.next(response.error.message);
            this.notificationService.error(response.error.message);
          }
        }),
        map(({ user }) => user)
      );
  }

  register(
    firstName: string,
    lastName: string,
    companyName: string,
    email: string,
    password: string,
    phoneNumber: number,
    trialType: TrialType
  ): Observable<any> {
    return this.http.post(
      authUrl + '/register',
      {
        firstName,
        lastName,
        companyName,
        email,
        password,
        phoneNumber,
        trialType
      },
      httpOptions
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(
      authUrl + '/forgot-password',
      {
        email
      },
      httpOptions
    );
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post(
      authUrl + '/reset-password',
      {
        token,
        password
      },
      httpOptions
    );
  }

  logout(): void {
    const request = this.http.post(authUrl + '/logout', {}, httpOptions);

    request.subscribe({
      next: (data: any) => {
        console.log('LimSearch: User logged out');
        this.deleteUser();
        window.location.href = '/';
      },
      error: (response) => {
        console.log('LimSearch: Error logging out');
        this.deleteUser();
        this._error.next(response.error.message);
        window.location.href = '/';
      }
    });
  }

  verifyRecaptcha(token: string): Observable<any> {
    const request = this.http.post(
      authUrl + '/verify-captcha',
      {
        token
      },
      httpOptions
    );
    return request;
  }

  saveVendorPrefs(vendorPrefs: object): void {
    const user = this.user();
    if (!user) {
      const errorMessage = 'Current user not logged in, cannot update account';
      this._error.next(errorMessage);
      this.notificationService.error(errorMessage);
      throw new Error(errorMessage);
    }

    this.http
      .post<{ vendorPrefs: VendorPreference[] }>(
        userUrl + '/vendor-prefs',
        {
          vendors: vendorPrefs
        },
        httpOptions
      )
      .subscribe({
        next: (pref: { vendorPrefs: VendorPreference[] }) => {
          user.vendorPrefs = pref.vendorPrefs;
          this.saveUser(user);
          this._error.next(null);
        },
        error: (response) => {
          this._error.next(response.error.message);
        }
      });
  }

  createApiKey(): void {
    this.http.post(userUrl + '/api-key', {}, httpOptions).subscribe({
      next: (data: any) => {
        this.apiKey.set(data.apiKey);
        this.notificationService.success('API key created successfully!');
      },
      error: (response) => {
        this._error.next(response.error.message);
        this.notificationService.error(response.error.message);
      }
    });
  }

  loginCMMSUser(authorizationCode): Observable<User> {
    // This will always return data. Based on the env it would be mock/actual CMMS user data.
    const request = this.http.post<User>(`${authUrl}/cmms/user`, { authorizationCode }, httpOptions).pipe(
      tap({
        next: (data: User) => {
          this.saveUser(data);
        },
        error: (response) => {
          this.isCmmsUser.set(false);
          this._error.next(response.error.message);
        }
      })
    );
    return request;
  }

  authorizeCMMSEndpoint(): Observable<any> {
    const request = this.http.get(`${authUrl}/cmms/authorize-endpoint`, httpOptions);
    return request;
  }
  updateCMMSUser(user: User) {
    if (!user.cmmsUserId || user.cmmsUserId === 0) {
      return;
    }
    this.isCmmsUser.set(true);
    if (user.superUser !== null) {
      this.superUser.set(user.superUser);
    }
    this.superUserEmail.set(user.superUserEmail);
  }
  removeCMMSUser() {
    this.isCmmsUser.set(false);
    this.superUser.set(false);
    this.superUserEmail.set('');
  }
}
